import React from 'react';

import Layout from '../components/layout';
import withBg from '../helpers/withBg';
import SEO from '../components/seo';
import TrustpilotHorizontal from '../components/TrustpilotHorizontal/index';
import SidebarStaticPage from '../components/Sidebar/SidebarStaticPage';
import './styles.css';

const data = {
  title: 'Affiliates',
  seoTitle: 'Affiliates | Travelopod',
  seoDescription:
    'Find and book the cheapest flights to any destination at Travelopod! Save money on unique deals for flights, hotels and car rentals online or call us 24/7 for live booking assistance.'
};

class affiliatesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: props.phoneNumber
    };
  }

  render() {
    const { phoneNumber } = this.state;
    const description = data.seoDescription;
    const seotitle = data.seoTitle;

    return (
      <Layout
        isStaticPage
        passPhoneNumber={phoneNumber => {
          this.setState({
            phoneNumber
          });
        }}
      >
        <SEO title={seotitle} description={description} />
        <div className="main c-content-page">
          <div className="page-content">
            <div className="flight-content">
              <div className="text-block">
                <h1>Affiliates</h1>
                <p>
                  Travelopod is a team of global travel specialists that offer
                  comprehensive travel solutions at competitive rates along with
                  excellent service. With offices in USA and India, we offer
                  comprehensive service to leisure and small-business travelers.
                  We have made our niche in certain areas such as International
                  ticketing and customized holidays.
                </p>
                <p>
                  In the international air ticketing business, we have made our
                  mark as one of the best agents for inexpensive tickets to the
                  Indian subcontinent from USA. Our highlight lies in our best
                  rates and supreme customer service. We provide you round the
                  clock, 24 X 7 bookings by phone, online chat or through our
                  website www.Travelopod.com.
                </p>
                <p>
                  We help companies like yours which could be airline companies,
                  online travel agencies or online marketers to earn money by
                  offering a set commission on air-ticket reservations and
                  holiday package bookings. This program is free with no hidden
                  costs. I Joining our Affiliate Program is quick and simple.
                </p>
                <p>
                  If you would like to include your products or services on
                  Travelopod.com, please fill up our query form with your
                  company name, contact details and a brief description of your
                  offerings. Send us query
                </p>
                <h2>Benefits</h2>
                <p>- Our affiliate program offers:-</p>
                <ul>
                  <li>
                    Access to our latest offers and newest deals and holiday
                    packages.
                  </li>
                  <li>
                    Entitlement to highly competitive monthly commissions on
                    each booking.
                  </li>
                  <li>Commissions on bookings.</li>
                  <li>Extra Incentives on high volume bookings.</li>
                  <li>Tracking your account real time, 24* 7.</li>
                </ul>
                <h2>Start earning: Become Affiliate please contact us at</h2>
                <p>
                  <a className="o-phone-info" href="mailto:info@travelopod.com">
                    info@travelopod.com
                  </a>
                </p>
              </div>
            </div>
            <SidebarStaticPage phoneNumber={phoneNumber} />
          </div>
        </div>
        <TrustpilotHorizontal />
      </Layout>
    );
  }
}

export default withBg(affiliatesPage);
